ion-segment {
  --background: var(--ion-color-lightest);
}

ion-segment-button {
  .md & {
    --background: var(--ion-color-lightest);
    --background-checked: var(--ion-color-lightest);
    --color: var(--ion-color-lightest-contrast);
    --color-checked: var(--ion-color-primary);
  }

  .ios & {
    --color-checked: var(--ion-color-primary);
    --color-activated: var(--ion-color-primary);
  }
}
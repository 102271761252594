:root {
  --ion-color-primary: #77B062;
  --ion-color-primary-rgb: 119, 176, 98;
  --ion-color-primary-contrast: var(--ion-color-lightest);
  --ion-color-primary-contrast-rgb: var(--ion-color-lightest-contrast-rgb);
  --ion-color-primary-shade: #699b56;
  --ion-color-primary-tint: #85b872;
  --ion-color-secondary: #DE7439;
  --ion-color-secondary-rgb: 222, 116, 57;
  --ion-color-secondary-contrast: var(--ion-color-light);
  --ion-color-secondary-contrast-rgb: var(--ion-color-light-contrast-rgb);
  --ion-color-secondary-shade: #c36632;
  --ion-color-secondary-tint: #e1824d;
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;
  --ion-color-danger: #f53d3d;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d83636;
  --ion-color-danger-tint: #f65050;
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  --ion-color-dark-grey: #505050;
  --ion-color-dark-rgb: 80, 80, 80;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #464646;
  --ion-color-dark-tint: #626262;
  --ion-color-medium: #868585;
  --ion-color-medium-rgb: 134, 133, 133;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #767575;
  --ion-color-medium-tint: #929191;
  --ion-color-light: #EFEFF4;
  --ion-color-light-rgb: 239,239,244;
  --ion-color-light-contrast: var(--ion-color-medium);
  --ion-color-light-contrast-rgb: var(--ion-color-medium-rgb);
  --ion-color-light-shade: #d2d2d7;
  --ion-color-light-tint: #f1f1f5;
  --ion-color-lightest: #ffffff;
  --ion-color-lightest-rgb: 255,255,255;
  --ion-color-lightest-contrast: var(--ion-color-medium);
  --ion-color-lightest-contrast-rgb: var(--ion-color-medium-rgb);
  --ion-color-lightest-shade: var(--ion-color-light);
  --ion-color-lightest-tint: var(--ion-color-medium);
  --ion-background-color: var(--ion-color-lightest);
  --ion-font-family: "Source Sans Pro";
  --ion-text-color: var(--ion-color-medium);
  --ion-margin: 16px;
  --ion-padding: 16px;
  --ion-safe-area-left: 8px;
  --ion-safe-area-right: 8px;
  --ion-safe-area-top: 8px;
  --font-size-xs: 11px;
  --font-size-s: 12px;
  --font-size-m: 14px;
  --font-size-l: 16px;
  --font-size-xl: 18px;
  --z-index-top: 1000;
}
.ios :root {
  --ion-color-dark: #262626;
  --ion-color-dark-rgb: 38, 38, 38;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #212121;
  --ion-color-dark-tint: #3c3c3c;
}

.ion-color-lightest {
  --ion-color-base: #ffffff;
  --ion-color-base-rgb: 255,255,255;
  --ion-color-contrast: var(--ion-color-medium);
  --ion-color-contrast-rgb: var(--ion-color-medium-rgb);
  --ion-color-shade: var(--ion-color-light);
  --ion-color-tint: var(--ion-color-medium);
}

.ion-color-dark-grey {
  --ion-color-base: #505050;
  --ion-color-base-rgb: 80, 80, 80;
  --ion-color-contrast: var(--ion-color-lightest);
  --ion-color-contrast-rgb: var(--ion-color-lightest-rgb);
  --ion-color-shade: var(--ion-color-dark);
  --ion-color-tint: var(--ion-color-medium);
}
ion-button {
  font-family: "Varela";
  text-transform: lowercase;
  letter-spacing: normal;
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --box-shadow: none;
}

.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;

  ion-button {
    --padding-start: var(--ion-padding);
    --padding-end: var(--ion-padding);
  }
}
@font-face {
  font-family: "Varela";
  src: url("assets/fonts/varela.woff2") format("woff2"),
  url("assets/fonts/varela.woff") format("woff"),
  url("assets/fonts/varela.ttf") format("truetype"),
  url("assets/fonts/varela.svg#VarelaRound-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('assets/fonts/SourceSansPro-Regular.woff2') format('woff2'),
  url('assets/fonts/SourceSansPro-Regular.woff') format('woff'),
  url('assets/fonts/SourceSansPro-Regular.ttf') format('truetype'),
  url('assets/fonts/SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('assets/fonts/SourceSansPro-Bold.woff2') format('woff2'),
  url('assets/fonts/SourceSansPro-Bold.woff') format('woff'),
  url('assets/fonts/SourceSansPro-Bold.ttf') format('truetype'),
  url('assets/fonts/SourceSansPro-Bold.svg#SourceSansPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

body {
  font-size: var(--font-size-m);
  color: var(--ion-color-dark);
}

h1,
.h1 {
  font-family: "Varela";
  font-size: 23px;
  margin: 0;
  color: var(--ion-color-primary);
}

h2,
.h2 {
  font-family: "Varela";
  font-size: 24px;
  color: var(--ion-color-dark);
  margin: var(--ion-margin) 0;

  @media screen and (max-width: 360px) {
    font-size: 20px;
  }
}

h3,
.h3 {
  font-family: "Varela";
  font-size: var(--font-size-l);
  color: var(--ion-color-dark);
  margin: var(--ion-margin) 0;
}

p {
  margin: var(--ion-margin) 0;
  line-height: 1.5;
  color: var(--ion-color-dark);
  font-size: var(--font-size-m);
}

a {
  text-decoration: none;
}
ion-toolbar {
  --background: var(--ion-color-lightest);
  --color: var(--ion-color-dark);
}

ion-header {
  --ion-safe-area-top: 0;

  .ios & {
    border-bottom: 1px solid var(--ion-color-light-shade);
  }
}

.title-ios {
  padding: 0 60px;
}
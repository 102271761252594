.hidden-ios {
  .ios & {
    display: none !important;
  }
}

.hidden-md {
  .md & {
    display: none !important;
  }
}
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import '~mapbox-gl/dist/mapbox-gl.css';
@import '~@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.css';
ion-toolbar {
  --background: var(--ion-color-lightest);
  --color: var(--ion-color-dark);
}

ion-header {
  --ion-safe-area-top: 0;
}
.ios ion-header {
  border-bottom: 1px solid var(--ion-color-light-shade);
}

.title-ios {
  padding: 0 60px;
}

.md ion-menu-button {
  --color: var(--ion-color-light);
}
.ios ion-menu-button {
  --color: var(--ion-color-dark);
}

ion-segment {
  --background: var(--ion-color-lightest);
}

.md ion-segment-button {
  --background: var(--ion-color-lightest);
  --background-checked: var(--ion-color-lightest);
  --color: var(--ion-color-lightest-contrast);
  --color-checked: var(--ion-color-primary);
}
.ios ion-segment-button {
  --color-checked: var(--ion-color-primary);
  --color-activated: var(--ion-color-primary);
}

ion-button {
  font-family: "Varela";
  text-transform: lowercase;
  letter-spacing: normal;
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --box-shadow: none;
}

.steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.steps ion-button {
  --padding-start: var(--ion-padding);
  --padding-end: var(--ion-padding);
}

ion-list ion-item {
  color: black;
}
ion-list ion-icon {
  color: var(--ion-color-primary);
}

ion-item {
  --background: var(--ion-color-lightest);
}
ion-item.bordered {
  border: 1px solid var(--ion-color-medium);
}

.legend-popover {
  --min-width: 75%;
}

@font-face {
  font-family: "Varela";
  src: url("assets/fonts/varela.woff2") format("woff2"), url("assets/fonts/varela.woff") format("woff"), url("assets/fonts/varela.ttf") format("truetype"), url("assets/fonts/varela.svg#VarelaRound-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("assets/fonts/SourceSansPro-Regular.woff2") format("woff2"), url("assets/fonts/SourceSansPro-Regular.woff") format("woff"), url("assets/fonts/SourceSansPro-Regular.ttf") format("truetype"), url("assets/fonts/SourceSansPro-Regular.svg#SourceSansPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Source Sans Pro";
  src: url("assets/fonts/SourceSansPro-Bold.woff2") format("woff2"), url("assets/fonts/SourceSansPro-Bold.woff") format("woff"), url("assets/fonts/SourceSansPro-Bold.ttf") format("truetype"), url("assets/fonts/SourceSansPro-Bold.svg#SourceSansPro-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}
body {
  font-size: var(--font-size-m);
  color: var(--ion-color-dark);
}

h1,
.h1 {
  font-family: "Varela";
  font-size: 23px;
  margin: 0;
  color: var(--ion-color-primary);
}

h2,
.h2 {
  font-family: "Varela";
  font-size: 24px;
  color: var(--ion-color-dark);
  margin: var(--ion-margin) 0;
}
@media screen and (max-width: 360px) {
  h2,
.h2 {
    font-size: 20px;
  }
}

h3,
.h3 {
  font-family: "Varela";
  font-size: var(--font-size-l);
  color: var(--ion-color-dark);
  margin: var(--ion-margin) 0;
}

p {
  margin: var(--ion-margin) 0;
  line-height: 1.5;
  color: var(--ion-color-dark);
  font-size: var(--font-size-m);
}

a {
  text-decoration: none;
}

.page-content ion-content {
  --background: var(--ion-color-lightest);
  --padding-bottom: 20px;
  --padding-end: 20px;
  --padding-start: 20px;
  --padding-top: 20px;
}

.ios .hidden-ios {
  display: none !important;
}

.md .hidden-md {
  display: none !important;
}